import { useRef, useEffect } from "react";
import ShapeRenderer from "./render";

import "./App.scss";

const App = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (canvasRef.current !== undefined) {
      console.log("running");
      const canvas = canvasRef.current as HTMLCanvasElement;
      const shapeRender = new ShapeRenderer(canvas);
      shapeRender.render();
    }
  }, [canvasRef]);

  return (
    <div>
      <canvas className="webgl" ref={canvasRef} />
      {/* <div className="content">Test render on top of a 3d object</div> */}
    </div>
  );
};

export default App;
