import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";

class ShapeRenderer {
  constructor(protected canvas: HTMLCanvasElement) {}

  render = () => {
    const light = new THREE.PointLight(0xffffff);
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGL1Renderer({ canvas: this.canvas });

    light.intensity = 1;

    scene.add(light);

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    renderer.setSize(sizes.width, sizes.height);

    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      100
    );

    camera.position.z = 14;
    camera.position.y = 3;

    /**
     * Setup boxes
     */
    const count = 300;
    let tempCube = new THREE.SphereGeometry(0.5);

    for (let i = 0; i < count; i++) {
      let material = new THREE.MeshStandardMaterial();

      let distance = 10;
      let position = {
        x: distance * (Math.random() - 0.5),
        y: distance * (Math.random() - 0.5),
        z: distance * (Math.random() - 0.5),
      };

      if (i % 2 === 0) {
        material.color.set(0xff0000);
      } else if (i % 3 === 0) {
        material.color.set(0x0000ff);
      } else {
        material.color.set(0x00ff00);
      }

      let mesh = new THREE.Mesh(tempCube, material);
      mesh.position.x = position.x * 3;
      mesh.position.y = position.y * 3;
      mesh.position.z = position.z * 3;
      scene.add(mesh);
    }

    /**
     * Make text
     */
    const font = new FontLoader();
    font.load("fonts/helvetiker_regular.typeface.json", (font) => {
      const textGeometry = new TextGeometry("Hello, World!", {
        font: font,
        size: 3,
        height: 1,
        curveSegments: 5,
        bevelEnabled: true,
        bevelThickness: 0.2,
        bevelSize: 0.03,
        bevelOffset: 0,
        bevelSegments: 5,
      });

      textGeometry.center();
      const textMaterial = new THREE.MeshNormalMaterial();
      const text = new THREE.Mesh(textGeometry, textMaterial);
      text.position.x = 0;
      text.position.y = 0;
      text.position.z = -2;
      scene.add(text);
    });

    // Resize function
    const resize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
    };

    // resizing listener
    window.addEventListener("resize", () => {
      resize();
    });

    const clock = new THREE.Clock();

    function animate() {
      requestAnimationFrame(animate);
      // const elapsed = clock.getElapsedTime();

      resize();

      // light.intensity = Math.max(Math.sin(elapsed), 0.5);

      const rotationSpeed = 0.002;
      camera.position.x =
        camera.position.x * Math.cos(rotationSpeed) +
        camera.position.z * Math.sin(rotationSpeed);
      camera.position.z =
        camera.position.z * Math.cos(rotationSpeed) -
        camera.position.x * Math.sin(rotationSpeed);

      camera.lookAt(new THREE.Vector3(0, 0, 0));
      camera.updateProjectionMatrix();
      // controls.update();

      renderer.render(scene, camera);
    }

    animate();
  };
}

export default ShapeRenderer;
